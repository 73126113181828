import { useCallback, useEffect } from "react";
import { useAnalytics } from "./useAnalytics";
export const useButtonsAnalytics = ()=>{
    const { onButtonClick  } = useAnalytics();
    const listenEvent = useCallback((e)=>{
        if (!e || !e.target) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        onButtonClick(e.target.textContent);
    }, []);
    useEffect(()=>{
        if (!document) return;
        const buttons = document.querySelectorAll("button");
        buttons.forEach((button)=>button.addEventListener("click", listenEvent));
        return ()=>{
            buttons.forEach((button)=>button.removeEventListener("click", listenEvent));
        };
    }, []);
};
