import { XIcon } from '@heroicons/react/outline';
import React from 'react';
import classNames from 'classnames';

export interface CloseButtonProps {
  onClick(): void;

  className?: string;
}

export const CloseButton = React.forwardRef<
  HTMLButtonElement,
  CloseButtonProps
>(({ onClick, className }, ref) => (
  <button
    type="button"
    className={classNames(
      'flex items-center justify-center h-10 w-10 rounded-full focus:outline-none',
      className
    )}
    onClick={onClick}
    ref={ref}
  >
    <XIcon className="h-5 w-5" aria-hidden="true" />
  </button>
));
