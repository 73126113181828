import React from 'react';
import { IconProps } from '../Icons.interface';

export const ShareIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.78875 8.89446C5.92342 8.62514 5.99942 8.32116 5.99942 7.99985C5.99942 7.67854 5.92342 7.37456 5.78875 7.10524M5.78875 8.89446C5.58722 9.29736 5.25555 9.62042 4.84748 9.81131C4.43941 10.0022 3.97883 10.0497 3.54037 9.94619C3.10191 9.84266 2.71125 9.59414 2.43168 9.24088C2.15211 8.88763 2 8.45034 2 7.99985C2 7.54936 2.15211 7.11207 2.43168 6.75882C2.71125 6.40556 3.10191 6.15704 3.54037 6.05351C3.97883 5.94998 4.43941 5.99751 4.84748 6.18839C5.25555 6.37928 5.58722 6.70234 5.78875 7.10524M5.78875 8.89446L10.2101 11.105M5.78875 7.10524L10.2101 4.89472M10.2101 11.105C9.97283 11.5795 9.93379 12.1289 10.1016 12.6322C10.2694 13.1355 10.6302 13.5515 11.1048 13.7888C11.5793 14.0261 12.1287 14.0651 12.6321 13.8973C13.1354 13.7296 13.5515 13.3687 13.7888 12.8942C14.0261 12.4197 14.0651 11.8703 13.8973 11.367C13.7296 10.8637 13.3687 10.4476 12.8941 10.2104C12.6591 10.0929 12.4033 10.0228 12.1413 10.0042C11.8792 9.9856 11.6161 10.0188 11.3668 10.1018C10.8635 10.2696 10.4474 10.6305 10.2101 11.105ZM10.2101 4.89472C10.3276 5.12964 10.4902 5.33912 10.6887 5.5112C10.8871 5.68328 11.1175 5.81458 11.3667 5.89763C11.6159 5.98067 11.8791 6.01381 12.1411 5.99518C12.4031 5.97654 12.6589 5.90648 12.8938 5.78899C13.1287 5.67151 13.3382 5.50891 13.5103 5.31047C13.6824 5.11203 13.8137 4.88164 13.8968 4.63246C13.9798 4.38327 14.013 4.12017 13.9943 3.85818C13.9757 3.59618 13.9056 3.34042 13.7881 3.1055C13.5508 2.63106 13.1348 2.2703 12.6315 2.10259C12.1282 1.93488 11.5789 1.97396 11.1044 2.21123C10.63 2.44849 10.2692 2.86451 10.1015 3.36776C9.93375 3.87101 9.97283 4.42028 10.2101 4.89472Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
