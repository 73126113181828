import React, { Fragment, Ref, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseButton } from '@parkschannel/ui';
import classNames from 'classnames';

export interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  testId?: string;
  title?: string;
  contentClassName?: string;
  closeOnOutside?: boolean;
  exitButton?: (
    onClose: () => void,
    cancelRef: Ref<HTMLButtonElement>
  ) => React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  setIsOpen,
  testId,
  title,
  contentClassName,
  closeOnOutside = true,
  exitButton,
}: ModalProps) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100]"
        initialFocus={cancelButtonRef}
        onClose={() => (closeOnOutside ? setIsOpen(false) : null)}
        data-testid={testId}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto lg:p-3">
          <div className="flex items-center justify-center min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  `relative bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all  sm:p-6 flex flex-col w-full lg:rounded-2xl `,
                  contentClassName
                )}
              >
                {title && (
                  <div className={classNames('pb-4')}>
                    <h2 className="font-bold text-lg text-neutral-700 mt-1">
                      {title}
                    </h2>
                  </div>
                )}
                <div className="absolute top-4 right-4 z-50">
                  {exitButton ? (
                    exitButton(() => setIsOpen(false), cancelButtonRef)
                  ) : (
                    <CloseButton
                      onClick={() => setIsOpen(false)}
                      ref={cancelButtonRef}
                    />
                  )}
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
