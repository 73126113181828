import { VideoApi } from "../Api";
import { useQuery } from "@tanstack/react-query";
export const useVideo = ()=>{
    const videoApi = new VideoApi();
    const useVideoListQuery = (boundingBox, zoom)=>{
        const { data , ...rest } = useQuery([
            "videos",
            "map",
            boundingBox,
            zoom
        ], async ()=>await videoApi.getMapVideos(boundingBox, zoom), {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            enabled: Array.isArray(boundingBox)
        });
        const onlyProperties = ((data === null || data === void 0 ? void 0 : data.clusters) || []).map((point)=>point.properties);
        return {
            data: {
                videos: onlyProperties,
                videosCount: (data === null || data === void 0 ? void 0 : data.videosCount) || 0
            },
            ...rest
        };
    };
    const useGetVideoQuery = (idOrSlug)=>useQuery([
            "videos",
            idOrSlug
        ], async ()=>await videoApi.getMediaData(idOrSlug), {
            refetchOnMount: false,
            enabled: !!idOrSlug
        });
    return {
        useVideoListQuery,
        useGetVideoQuery
    };
};
