import { AccountApi } from "../Api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export const useUser = ()=>{
    const userApi = new AccountApi();
    const queryClient = useQueryClient();
    const useSubscribeAccountMutationQuery = function() {
        let { onSuccess , onError  } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        return useMutation((userId)=>userApi.subscribe(userId), {
            onSuccess,
            onError,
            onSettled: async ()=>{
                queryClient.invalidateQueries([
                    "subscriptions"
                ]);
                queryClient.invalidateQueries([
                    "userProfile"
                ]);
            }
        });
    };
    return {
        useSubscribeAccountMutationQuery
    };
};
