import React, { createContext, useCallback, useContext, useState } from 'react';
import useDebounce from './useDebounce';

type IBounds = [number, number, number, number];

interface IVideoModalContext {
  mapBounds: IBounds | null;
  setMapBounds: (bounds: IBounds) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
}

export const MapBoundsContext = createContext<IVideoModalContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMapBounds: () => {},
  mapBounds: null,
  zoom: 3,
  setZoom: () => null,
});
const useSimpleMapBounds = () => {
  return useContext(MapBoundsContext);
};
export const useMapBounds = () => {
  const { mapBounds, zoom, ...rest } = useSimpleMapBounds();

  const debouncedMapBounds = useDebounce(mapBounds, 100);
  const debouncedZoom = useDebounce(zoom, 100);

  return {
    mapBounds: debouncedMapBounds,
    superclusterBounds: mapBoundsToSuperclusterBounds(debouncedMapBounds),
    roundedBounds: roundBounds(debouncedMapBounds),
    zoom: debouncedZoom,
    ...rest,
  };
};

interface IVideoModalProvider {
  children: React.ReactNode;
  defaultBounds: IBounds;
  defaultZoom: number;
}

export const MapBoundsProvider: React.FC<IVideoModalProvider> = ({
  children,
  defaultBounds,
  defaultZoom,
}) => {
  const [zoom, setZoom] = useState(defaultZoom);
  const [mapBounds, setMapBounds] = useState(defaultBounds as IBounds);

  const handleSetMapBounds = useCallback((bounds: IBounds) => {
    setMapBounds(bounds);
  }, []);

  const handleSetMZoom = useCallback((z: number) => {
    setZoom(z);
  }, []);

  return (
    <MapBoundsContext.Provider
      value={{
        mapBounds,
        setMapBounds: handleSetMapBounds,
        zoom,
        setZoom: handleSetMZoom,
      }}
    >
      {children}
    </MapBoundsContext.Provider>
  );
};

function mapBoundsToSuperclusterBounds(boundingBox: IBounds | null) {
  if (!boundingBox) return boundingBox;
  return [boundingBox[1], boundingBox[2], boundingBox[3], boundingBox[0]];
}

function roundBounds(boundingBox: IBounds | null): IBounds | null {
  if (!boundingBox) return boundingBox;
  return [
    Math.ceil(boundingBox[0]),
    Math.floor(boundingBox[1]),
    Math.floor(boundingBox[2]),
    Math.ceil(boundingBox[3]),
  ];
}
