import React from 'react';
import classNames from 'classnames';

export interface ItemsListProps<T> {
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  className?: string;
  liClassName?: string;
  noItemsMessage?: string | React.ReactNode;
  noItemsClassName?: string;
}

export function ItemsList<T extends { id: string | number }>({
  items,
  renderItem,
  className,
  liClassName,
  noItemsMessage = 'Not found',
  noItemsClassName,
}: ItemsListProps<T>) {
  if (items.length === 0) {
    return (
      <span
        className={classNames(
          'font-medium text-sm text-neutral-900 my-5 text-center block',
          noItemsClassName
        )}
      >
        {noItemsMessage}
      </span>
    );
  }

  return (
    <ul className={classNames(className)}>
      {items.map((item) => (
        <li className={classNames('w-full', liClassName)} key={item.id}>
          {renderItem(item)}
        </li>
      ))}
    </ul>
  );
}
