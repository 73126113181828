import React, { memo } from 'react';
import classNames from 'classnames';
import { Typography } from '../Typography';

export type TagSize = 'sm' | 'md';
export type TagColor =
  | 'transparent'
  | 'yellow'
  | 'orange'
  | 'gray'
  | 'darkGray'
  | 'light'
  | string;

export interface TagProps {
  id?: string;
  value?: string | number | null;
  size?: TagSize;
  color?: TagColor;
  className?: string;
}

export const Tag: React.FC<TagProps> = memo(
  ({ id, value, size = 'sm', color = 'transparent', className }) => {
    return (
      <Typography
        variant="bodySmall"
        id={id}
        className={classNames(
          'normal-case rounded-full text-neutral-700 inline-block font-medium whitespace-nowrap',
          tagSize(size),
          tagColor(color),
          className
        )}
      >
        {typeof value === 'string' ? capitalizeFirstLetter(value) : value}
      </Typography>
    );
  }
);

function tagSize(size: TagSize) {
  switch (size) {
    case 'sm':
      return 'py-1 px-2';

    default:
      return 'py-2 px-4';
  }
}

function tagColor(color: TagColor) {
  switch (color) {
    case 'orange':
      return 'bg-orange1';
    case 'yellow':
      return 'bg-yellow1';
    case 'transparent':
      return 'bg-transparent';
    case 'gray':
      return 'bg-neutral-100';
    case 'darkGray':
      return 'bg-neutral-200';
    case 'light':
      return 'bg-white';
    default:
      return `bg-${color}`;
  }
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
