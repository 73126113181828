import { HttpClient } from "@parkschannel/next-utils";
import { isAxiosResponseError, ThumbnailType } from "@parkschannel/contracts";
export class VideoApi {
    async createMediaVideo() {
        return this.client.post("/api/media/videos");
    }
    async getMyMediaData(id) {
        return this.client.get("/api/accounts/me/videos/".concat(id));
    }
    async getMediaData(idOrSlug) {
        return this.client.get("/api/media/videos/".concat(idOrSlug), {}, false);
    }
    async submitMedia(id, data) {
        return this.client.patch("/api/media/videos/".concat(id), data);
    }
    async getVideoUpload(id) {
        return this.client.get("/api/media/videos/".concat(id, "/upload"));
    }
    async createThumbnail(id) {
        return this.client.post("/api/media/videos/".concat(id, "/thumbnail"), {});
    }
    async putAttachmentToSignedUrl(signedUrl, file) {
        await this.client.put(signedUrl, file, {
            headers: {
                "Content-Type": ""
            }
        });
    }
    async updateThumbnail(videoId, thumbnailId) {
        return this.client.patch("/api/media/videos/".concat(videoId, "/thumbnail/").concat(thumbnailId), {}).catch((e)=>{
            if (isAxiosResponseError(e) && e.response.status === 409) {
                throw new ThumbnailNotReadyError();
            }
            throw e;
        });
    }
    async createThumbnailFromStrip(id, index) {
        return this.client.post("/api/media/videos/".concat(id, "/thumbnail"), {
            type: ThumbnailType.STRIP,
            thumbstripIndex: index
        });
    }
    async getVideoStrips(id) {
        let size = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 320;
        return this.client.get("/api/jwplayer/strips/".concat(id, "-").concat(size, ".vtt"), {
            headers: {
                "Content-Type": "text/html"
            }
        });
    }
    async publish(id) {
        return this.client.post("/api/media/videos/".concat(id, "/publish"), {});
    }
    async submit(id) {
        return this.client.post("/api/media/videos/".concat(id, "/submit"), {});
    }
    async delete(id) {
        return this.client.delete("/api/media/videos/".concat(id), {});
    }
    async toggleLike(id) {
        return this.client.put("/api/media/videos/".concat(id, "/like"));
    }
    async getPopularVideos(params) {
        return this.client.get("/api/media/videos/popular", {
            params
        }, false);
    }
    async getPopularCreators(params) {
        return this.client.get("/api/media/accounts/popular", {
            params
        }, false);
    }
    async getMapVideos(boundingBox, zoom) {
        return this.client.get("/api/map/videos", {
            params: {
                algoliaBB: boundingBox,
                superclusterBB: mapBoundsToSuperclusterBounds(boundingBox),
                zoom
            }
        }, false);
    }
    constructor(client = new HttpClient()){
        this.client = client;
    }
}
export class ThumbnailNotReadyError extends Error {
}
function mapBoundsToSuperclusterBounds(boundingBox) {
    return [
        boundingBox[1],
        boundingBox[2],
        boundingBox[3],
        boundingBox[0]
    ];
}
