export function next(arr, currentItem, compareFn) {
    if (arr.length <= 1) return null;
    const currentItemIndex = arr.findIndex((item)=>compareFn(item, currentItem));
    if (currentItemIndex === -1) return null;
    const nextItemIndex = currentItemIndex === arr.length - 1 ? 0 : currentItemIndex + 1;
    return arr[nextItemIndex];
}
export function previous(arr, currentItem, compareFn) {
    if (arr.length <= 1) return null;
    const currentItemIndex = arr.findIndex((item)=>compareFn(item, currentItem));
    if (currentItemIndex === -1) return null;
    const previousItemIndex = currentItemIndex === 0 ? arr.length - 1 : currentItemIndex - 1;
    return arr[previousItemIndex];
}
export function takeNextLooped(arr, currentItem, compareFn) {
    let length = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : 1;
    if (arr.length <= 1) return [];
    const currentItemIndex = arr.findIndex((item)=>compareFn(item, currentItem));
    if (currentItemIndex === -1) return [];
    const nextItems = [];
    let nextItemIndex = currentItemIndex;
    for(let itemsCount = 0; itemsCount < length; itemsCount++){
        nextItemIndex = nextItemIndex === arr.length - 1 ? 0 : nextItemIndex + 1;
        if (nextItemIndex === currentItemIndex) break;
        nextItems.push(arr[nextItemIndex]);
    }
    return nextItems;
}
