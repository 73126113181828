import React from 'react';
import { IconProps } from '../Icons.interface';

export const LocationMarkerIcon: React.FC<
  IconProps & { color?: 'yellow' | 'orange' }
> = ({ className, color = 'yellow' }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.03982 3.23999C5.09008 2.18973 6.51453 1.5997 7.99982 1.5997C9.48511 1.5997 10.9096 2.18973 11.9598 3.23999C13.0101 4.29024 13.6001 5.7147 13.6001 7.19999C13.6001 8.68527 13.0101 10.1097 11.9598 11.16L7.99982 15.12L4.03982 11.16C3.51975 10.64 3.10721 10.0226 2.82574 9.34315C2.54428 8.66368 2.39941 7.93544 2.39941 7.19999C2.39941 6.46454 2.54428 5.73629 2.82574 5.05683C3.10721 4.37737 3.51975 3.76 4.03982 3.23999ZM7.99982 8.79999C8.42416 8.79999 8.83113 8.63141 9.13119 8.33136C9.43125 8.0313 9.59982 7.62433 9.59982 7.19999C9.59982 6.77564 9.43125 6.36867 9.13119 6.06862C8.83113 5.76856 8.42416 5.59999 7.99982 5.59999C7.57547 5.59999 7.16851 5.76856 6.86845 6.06862C6.56839 6.36867 6.39982 6.77564 6.39982 7.19999C6.39982 7.62433 6.56839 8.0313 6.86845 8.33136C7.16851 8.63141 7.57547 8.79999 7.99982 8.79999Z"
      fill={`url(#${color}-paint-id)`}
    />
    <defs>
      <linearGradient
        id={`${color}-paint-id`}
        x1="7.99976"
        y1="1.5997"
        x2="7.99976"
        y2="15.12"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={color === 'orange' ? '#FFF064' : '#FDF28A'} />
        <stop
          offset="1"
          stopColor={color === 'orange' ? '#FF8A00' : '#FFC700'}
        />
      </linearGradient>
    </defs>
  </svg>
);
